import React from "react"

import Layout from "../components/layout"
import "../utils/css/portfolio.css"
import ImageViewer from "../components/image-viewer"

import LocaionIcon from "../images/visualization/Visualitationview/map-pin-blue.svg"
import Modal from "../components/modal/Modal"
import ReactPannellum, {
  getConfig,
  getCurrentScene,
  addScene,
  getAllScenes,
  lookAt
} from "react-pannellum"
import "../utils/css/visualizationview.css"
import { Link } from "gatsby"

// Images
import fbIcon from "../images/portfolio/facebook.png"
import pinterestIcon from "../images/portfolio/pinterest.png"
import twitterIcon from "../images/portfolio/twitter.png"
import CloseIcon from "@material-ui/icons/Clear"

import Helmet from "react-helmet"
import SEO from "../components/seo"

import {
  FacebookShareButton,
  GooglePlusShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  FacebookIcon,
  TwitterIcon,
  PinterestIcon,
} from "react-share"

function SocialMedia({ url }) {
  return (
    <div className="social-media-pics-holder">
      <FacebookShareButton
        quote={url}
        className="social-media-pic"
        hashtag="#whitlockdesigns"
        url="https://www.whitlockdesigns.com"
      >
        <FacebookIcon size={12} round className="social-media-pic" />
      </FacebookShareButton>
      <TwitterShareButton
        title={url}
        // via="https://my-facebook-link.com"
        hashtags={["whitlockdesigns", "architecture"]}
        className="social-media-pic"
        url="https://www.whitlockdesigns.com"
      >
        <TwitterIcon size={12} round className="social-media-pic" />
      </TwitterShareButton>
      <PinterestShareButton
        media={url}
        description=""
        className="social-media-pic"
        url="https://www.whitlockdesigns.com"
      >
        <PinterestIcon size={12} round className="social-media-pic" />
      </PinterestShareButton>
    </div>
  )
}

const Caption = props => {
  return (
    <div className="captions-holder">
      <div className="caption-wrapper">
        <label>{props.caption}</label>
      </div>
      <SocialMedia url={props.url} />
    </div>
  )
}

class ViewProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openPreview: false,
      currentImage: 0,
      show: false,
      show2: false,
      show3: false,
      show4: false,
      mainImage: [],
      // thumbnailPano:false,
      // panoClickedSrc:"",
      // panoClickedHotspot:[],
      // panoClickedTitle:""
    }
    this.scrollRef = React.createRef();
  }

  componentDidMount() {
    const {
      pageContext: { data },
    } = this.props

    const mainImage = data.mainImages.map(mainimage => {
      return mainimage.image.asset.url
    })
    this.setState({ mainImage })

  }

  showPreview = () => {
    this.setState({ openPreview: true })
  }

  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1,
    })
  }

  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1,
    })
  }

  closePreview = () => {
    this.setState({
      currentImage: 0,
      openPreview: false,
    })
  }

  gotoImage = index => {
    this.setState({
      currentImage: index,
    })
  }

// scrollLeft = () => {
//   console.log("hey left")

//     if(this.scrollRef.current){
//         this.scrollRef.current.scrollLeft -= 300;
//     }
// }

// scrollRight = () => {
//   console.log("hey right")
//   if(this.scrollRef.current){
//     this.scrollRef.current.scrollLeft += 300
//   }
// }

  // panoThumbnail = (singleAxon) => {
  //   console.log("hey panoThumbnail",singleAxon)
  //   this.setState({
  //                   thumbnailPano:!this.state.thumbnailPano,
  //                   panoClickedSrc:singleAxon.image.asset.url,
  //                   panoClickedHotspot:singleAxon.hotSpotsValue,
  //                   panoClickedTitle:singleAxon.title
  //                 })
  // }
  // autoGen = (axonAll)=>{
  //   this.setState({ show: true })
  //   axonAll.slice(1).map((singleAxon,i) => this.Face(singleAxon,i+2))
  // }

  // Face = (singleAxon,i)=>{
  //   addScene(`Scene${i}`, {
  //     title: singleAxon.title,
  //     author: "Whitlock Design Group",
  //     imageSource:  singleAxon.image.asset.url,
  //     hotSpots:  singleAxon.hotSpotsValue
  //   })
  // }


  render() {
    console.log("Props", this.props)
    // console.log("by RENDER getConfig",getConfig())
    const {
      pageContext: { data },
    } = this.props
    const { openPreview, currentImage, mainImage } = this.state

    //Seperate URL created//
    // const axonPanoramaImages = data.axonPanoramaImages.map(axonPanoramaImage => {return axonPanoramaImage.image.asset.url})
    // console.log("axonPanoramaImages", axonPanoramaImages)
    // const axonPanoramaTitle = data.axonPanoramaImages.map(axonPanoramaImage => {return axonPanoramaImage.title})
    // console.log("axonPanoramaTitle", axonPanoramaTitle)
    // const axonPanoramaHotspot = data.axonPanoramaImages.map(axonPanoramaImage => {return axonPanoramaImage.hotSpotsValue})
    // console.log("axonPanoramaHotspot", axonPanoramaHotspot)

    // const axonAll = data.axonPanoramaImages.map(axonPanoramaImage => {return axonPanoramaImage})
    // console.log("axonAll", axonAll)

    // const panoTitle = this.state.panoClickedTitle ? this.state.panoClickedTitle : axonPanoramaTitle[0]
    // const panohotSpots = this.state.panoClickedSrc ?  this.state.panoClickedHotspot : axonPanoramaHotspot[0]
    // const panoImageSource =  this.state.panoClickedSrc ? this.state.panoClickedSrc : axonPanoramaImages[0]

    const images =
      data.portfolioImages &&
      data.portfolioImages.map((project, index) => {
        return {
          src: project.image.asset && project.image.asset.url,
          caption: (
            <Caption
              caption={project.title && project.title}
              url={project.image.asset && project.image.asset.url}
            />
          ),
        }
      })

  return (
      <Layout>
        <SEO
          title={`${data.title}`}
          description={`Boston Interior and Boston Interiors.quincy market. We have interior design ideas, we went to interior design schools ,we create interior design styles , interior design software, we provide interior design jobs, interior design Boston,interior designer boston,Boston Interior and Boston Interiors at Boston Logan airport. Here it shows about ${data.title}.`}
        />
        <Helmet>
          <link rel="canonical" href={`/rendering/${data.slug.current}`} />
        </Helmet>
        <div className="nav-avoider" />
        {this.state.openPreview && (
          <div
            style={{
              height: "100vh",
              width: "100%",
              backgroundColor: "rgba(0,0,0,0.9)",
              position: "fixed",
              paddingTop: "20px",
              top: 0,
              zIndex: 30,
            }}
          >
            <CloseIcon
              onClick={() => this.closePreview()}
              style={{
                fill: "white",
                position: "absolute",
                zIndex: 100,
                right: 40,
                top: 20,
                height: 40,
                width: 40,
                marginTop: 20,
                cursor: "pointer",
              }}
            />
            <ImageViewer
              currentIndex={this.state.currentImage}
              images={data.visualizationImages.map(
                project => project.image.asset.url
              )}
              thumbnails={data.visualizationImages.map(
                project => project.image.asset.url
              )}
              // showClose={true}
            />
          </div>
        )}
        <h1 className="portfolio-title heading">{data.title}</h1>
        <div className="about-visualization__wrapper">
          <p id="about-visualization__para" style = {{textAlign : "justify"}}>
            {data.description}
          </p>
        </div>
        <main className="visualization-view-main">
          <div className="visualization-sub-heading">
            <h1>RENDERING</h1>
          </div>
          <div className="rendering-Container">
            <div className="Image-Container">
              {data.visualizationImages &&
                data.visualizationImages.map((project, index) => {
                  return (
                    <div
                      // className="flex-row-item"
                      className="sample-model-Limage1"
                      key={project.id}
                      onClick={() => {
                        this.gotoImage(index)
                        this.showPreview()
                      }}
                    >
                      <img
                        src={project.image.asset && project.image.asset.url}
                        // className="flex-row-images"
                        alt={`"${project.title && project.title}"`}
                        id="check"
                        title="project"
                      />
                    </div>
                  )
                })}
            </div>
          </div>
          {data.axonImage ?
          <div className="Image-Container">
            <h1 style={{color: "#fff"}}>AXON VIEW</h1>
                  <figure className="sample-model-axon-image1">
                    <img
                      className="visual-view-gallery-axon-bg-img"
                      src={data.axonImage.asset && data.axonImage.asset.url}
                    />                   
                  </figure>
                  <Link to={`/viewPanorama/${data.slug.current}`}>
                  <div className={`axon-dot-${data.slug.current} axon-dot`} 
                    // onClick={() => this.autoGen(axonAll)}
                    >
                    
                    <button className="visit-panellum-btn">See Inside {data.title}</button>
                  </div>
                  </Link> 
                  {/* <Link to={`/viewPanorama/${data.slug.current}`}>
                  <div className={`axon-dot-${data.slug.current} axon-dot`} 
                    // onClick={() => this.autoGen(axonAll)}
                    >
                    <img
                      src={LocaionIcon}
                      className="bounce"
                      alt="locationIcon"
                    />
                    <p>Visit {data.slug.current}</p>
                  </div>
                  </Link> */}
          </div> : null}

          {/* <Modal
            show={this.state.show}
            onClose={() => this.setState({ show: false })}
            scrollRight={this.scrollRight}
            scrollLeft={this.scrollLeft}
          >
            <div className="content" style={{backgroundColor:"black",height:"100%"}}>
              {!this.state.thumbnailPano ?
              <ReactPannellum
                id="1"
                sceneId="Scene1"
                imageSource={panoImageSource}
                config={{
                  title: panoTitle,
                  // autoRotate: -1,
                  autoLoad: true,
                  author: "Whitlock Design Group",
                // hotSpotDebug:true,
                  hotSpots: panohotSpots
                }}
                style={{ width: "100%", height: "90%" }}
              /> : ""}
              {this.state.thumbnailPano ?
              <ReactPannellum
                id="1"
                sceneId="Scene1"
                imageSource={panoImageSource}
                config={{
                  title: panoTitle,
                  // autoRotate: -1,
                  autoLoad: true,
                  author: "Whitlock Design Group",
                // hotSpotDebug:true,
                  hotSpots: panohotSpots
                }}
                style={{ width: "100%", height: "90%" }}
              /> : ""}

              <div className={axonAll.length >= 7 ? "pano-thumbnail-preview":"pano-thumbnail-preview-center" }ref={this.scrollRef} >
              {axonAll.map(singleAxon =>
                  <img src={singleAxon.image.asset.url} alt="not yet" className="pano-image-preview" onClick={()=> {
                    this.panoThumbnail(singleAxon);
                    this.autoGen(axonAll)
                  }}/>
                )}
              </div>
            </div>
          </Modal> */}


          {data.mainImages && data.mainImages.length != 0  ?
          <div className="Image-Container">
            <h1 style={{color : "#fff"}}>MAIN LEVEL</h1>
            <figure className="sample-model-axon-image1">
              <img class="visual-view-gallery-axon-img" src={mainImage[0]} />
            </figure>
            <h1 style={{color : "#fff"}}>MEZZANINE LEVEL</h1>
            <figure className="sample-model-axon-image1">
              <img class="visual-view-gallery-axon-img" src={mainImage[1]} />
            </figure>
          </div>
          : null }

          <div className="visualization-sub-heading">

          </div>
          {data.visualizationVideos &&
            data.visualizationVideos.map((video, i) => {
              return (
                <div
                  key={i}
                  className="walk-through-video"
                  style={{ textAlign: "center", margin: "1rem" }}
                >
                  <h1 style = {{
                    color: "#fff",
                    textAlign:"left",
                    marginLeft: "172px",
                  }}>VIDEO WALK THROUGH</h1>
                  <iframe
                    width="1080px"
                    height="608px"
                    src={`${video.videoUrl}?rel=0`}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer;clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen="true"
                  ></iframe>
                </div>
              )
            })}
          <div className="portfolio-description">
            <p>{data.description}</p>
          </div>
        </main>
      </Layout>
    )
  }
}

export default ViewProject
